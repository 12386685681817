// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Link } from "react-router-dom";

// const Section9 = () => {
//   const [instagramData, setInstagramData] = useState([]);
//   const accessToken = "IGQWRPbktCbV9mQzc5NnVFX1NLU1lhaE9JT0NTYTlIYTFOZAkpNNjVHNHFwRUQ5R1dyeWl4LS1ld29CUFU5aU9PTzg3TFlUUnhIaF9QcGVGNXJkOVJYajBBajcxQktSQmV6TGJ1TTNhMXAxNExMV1lJamtuLV8zZAXMZD";

//   useEffect(() => {
//     const fetchInstagramData = async () => {
//       try {
//         const response = await axios.get(
//           `https://graph.instagram.com/v12.0/me/media`,
//           {
//             params: {
//               fields: "id,caption,media_type,media_url,permalink,thumbnail_url,timestamp",
//               access_token: accessToken,
//             },
//           }
//         );

//         setInstagramData(response.data.data);
//       } catch (error) {
//         console.error("Error fetching Instagram data:", error);
//       }
//     };

//     fetchInstagramData();

//     // const ashapuriInstaForm = document.getElementById("snapppt-widget");
//     // if (!ashapuriInstaForm?.hasChildNodes()) {
//     //   const script = document.createElement("script");
//     //   script.src =
//     //     "https://app.addsauce.com/widgets/liveig/d710d4c7-26ba-1b88-221f-231ca8d11d7f";
//     //   script.async = true;
//     //   ashapuriInstaForm?.appendChild(script);
//     // }
//   }, []);

//   return (
//     <>
//       {/* <section className="instagram-gallery">
//         <div className="container">
//           <h2>Instagram Feed</h2>
//           <div className="row">
//             {instagramData.map((post) => (
//               <div key={post.id} className="col-md-2 gallery-item">
//                 <div className="media-container">
//                   {post.media_type === "IMAGE" && (
//                     <img
//                       src={post.media_url}
//                       alt={post.caption}
//                       className="gallery-image"
//                     />
//                   )}
//                   {post.media_type === "VIDEO" && (
//                     <video controls className="gallery-image">
//                       <source src={post.media_url} type="video/mp4" />
//                     </video>
//                   )}
//                   <div className="overlay">
//                     <i className="fab fa-instagram"></i>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//           <form id="snapppt-widget"></form>
//         </div>
//       </section> */}

//       {/* <section className="instagram-gallery">
//         <div className="container">
//           <h2>Instagram Feed</h2>
//           <div className="row">
//             {instagramData.map((post, index) => (
//               <div className="row">
//                 {index === 0}
//                 <div key={post.id} className={` col-md-3 gallery-item`}>
//                   <div className="media-container">
//                     {post.media_type === "IMAGE" && (
//                       <img
//                         src={post.media_url}
//                         alt={post.caption}
//                         className="gallery-image"
//                       />
//                     )}
//                     {post.media_type === "VIDEO" && (
//                       <video controls className="gallery-image">
//                         <source src={post.media_url} type="video/mp4" />
//                       </video>
//                     )}
//                     <div className="overlay">
//                       <i className="fab fa-instagram"></i>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section> */}



//       <section className="instagram-gallery">
//         <div className="container">
//           <h2>Instagram Feed</h2>
//           <div className="row">

//           </div>
//           <div className="row">
//             {/* Left column */}
//             <div className="col-6">
//               <div className="row">
//                 {instagramData.slice(0, 1).map((post, index) => (
//                   <div key={post.id} className="col-12 gallery-item mb-3">
//                     <Link to={post.permalink}>
//                       <div className="media-container">
//                         {post.media_type === "IMAGE" && (
//                           <img
//                             src={post.media_url}
//                             alt={post.caption}
//                             className="gallery-image"
//                           />
//                         )}
//                         {post.media_type === "VIDEO" && (
//                           <video controls className="gallery-image">
//                             <source src={post.media_url} type="video/mp4" />
//                           </video>
//                         )}
//                         <div className="overlay">
//                           <i className="fab fa-instagram"></i>
//                         </div>
//                       </div>
//                     </Link>
//                   </div>
//                 ))}
//               </div>
//             </div>

//             {/* Right column */}
//             <div className="col-6">
//               <div className="row">
//                 {instagramData.slice(0, 4).map((post, index) => (
//                   <div key={post.id} className="col-6 gallery-item mb-3">
//                     <Link to={post.permalink}>   <div className="media-container">
//                       {post.media_type === "IMAGE" && (
//                         <im
//                           src={post.media_url}
//                           alt={post.caption}
//                           className="gallery-image"
//                         />
//                       )}
//                       {post.media_type === "VIDEO" && (
//                         <video controls className="gallery-image">
//                           <source src={post.media_url} type="video/mp4" />
//                         </video>
//                       )}
//                       <div className="overlay">
//                         <i className="fab fa-instagram"></i>
//                       </div>
//                     </div>
//                     </Link>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         </div>
//       </section >


//     </>
//   );
// };

// export default Section9;



import React, { useEffect } from "react";

const Section9 = () => {
  useEffect(() => {
    const ashapuriInstaForm = document.getElementById("snapppt-widget");

    if (!ashapuriInstaForm?.hasChildNodes()) {
      const script = document.createElement("script");
      script.src = 'https://app.addsauce.com/widgets/liveig/d710d4c7-26ba-1b88-221f-231ca8d11d7f';
      script.async = true;
      ashapuriInstaForm?.appendChild(script);
    }
  });

  return (
    <>
      <section className="instagram-bg">
        <div className="container container-xxl">
          <form id="snapppt-widget"></form>
        </div>
      </section>
    </>
  );
};

export default Section9;
